import { PRODUCT_TAG } from 'constants/product';
import { getBaseURL } from 'services/endpoints';

// Initial state
export const PROCESS_DETAILS_INITIAL_STATE = {
    processDetails: null,
    isRequesting: false,
    timeline: [],
    refreshProcessDetails: 0,
    statusList: [],
    partnerList: [],
    standbyReasonList: [],
    reasonCancellationList: [],
    reasonUnfeasibleList: []
}

export const PROCESS_STATE_IDS = {
    ADJUDICATED: 1,
    SITE_SURVEY_REQUESTED: 2,
    SITE_SURVEY_SCHEDULED: 3,
    SITE_SURVEY_EXECUTED: 4,
    PARTNER_PRICING_COMPLETED: 5,
    VALIDATED: 6,
    PAUSED: 7,
    CANCELLED: 8,
    REFORMULATED: 9,
    REFORMULATE_REQUEST_PARTNER: 10,
    REFORMULATE_REQUEST_SERVICE_MANAGER: 11,
    UNFEASIBLE: 12,
    ON_HOLD_EDP: 99,
    ACCEPTED: 100,
    CANCELLED_PARTNER: 101
};


//* ACTIONS *\\
export const REQUEST_GET_PROCESS_DETAILS = "REQUEST_GET_PROCESS_DETAILS";
export const REQUEST_GET_PROCESS_DETAILS_SUCCESS = "REQUEST_GET_PROCESS_DETAILS_SUCCESS";
export const REQUEST_GET_PROCESS_DETAILS_FAIL = "REQUEST_GET_PROCESS_DETAILS_FAIL";
export const REQUEST_GET_PROCESS_TIMELINE = "REQUEST_GET_PROCESS_TIMELINE";
export const REQUEST_GET_PROCESS_TIMELINE_SUCCESS = "REQUEST_GET_PROCESS_TIMELINE_SUCCESS";
export const REQUEST_GET_PROCESS_TIMELINE_FAIL = "REQUEST_GET_PROCESS_TIMELINE_FAIL";
export const REQUEST_POST_PROCESS_DISPATCH_SURVEY = "REQUEST_POST_PROCESS_DISPATCH_SURVEY";
export const REQUEST_POST_PROCESS_DISPATCH_SURVEY_SUCCESS = "REQUEST_POST_PROCESS_DISPATCH_SURVEY_SUCCESS";
export const REQUEST_POST_PROCESS_DISPATCH_SURVEY_FAIL = "REQUEST_POST_PROCESS_DISPATCH_SURVEY_FAIL";
export const REQUEST_GET_STATUS_LIST_BY_PROCESS = "REQUEST_GET_STATUS_LIST_BY_PROCESS";
export const REQUEST_GET_STATUS_LIST_BY_PROCESS_SUCCESS = "REQUEST_GET_STATUS_LIST_BY_PROCESS_SUCCESS";
export const REQUEST_GET_STATUS_LIST_BY_PROCESS_FAIL = "REQUEST_GET_STATUS_LIST_BY_PROCESS_FAIL";
export const REQUEST_GET_PARTNER_LIST = "REQUEST_GET_PARTNER_LIST";
export const REQUEST_GET_PARTNER_LIST_SUCCESS = "REQUEST_GET_PARTNER_LIST_SUCCESS";
export const REQUEST_GET_PARTNER_LIST_FAIL = "REQUEST_GET_PARTNER_LIST_FAIL";
export const REQUEST_GET_STANDBY_REASON_LIST = "REQUEST_GET_STANDBY_REASON_LIST";
export const REQUEST_GET_STANDBY_REASON_LIST_SUCCESS = "REQUEST_GET_STANDBY_REASON_LIST_SUCCESS";
export const REQUEST_GET_STANDBY_REASON_LIST_FAIL = "REQUEST_GET_PARTNER_LIST_FAIL";
export const REQUEST_GET_REASON_CANCELLATION_LIST = "REQUEST_GET_REASON_CANCELLATION_LIST";
export const REQUEST_GET_REASON_CANCELLATION_LIST_SUCCESS = "REQUEST_GET_REASON_CANCELLATION_LIST_SUCCESS";
export const REQUEST_GET_REASON_CANCELLATION_LIST_FAIL = "REQUEST_GET_REASON_CANCELLATION_LIST_FAIL";
export const REQUEST_GET_REASON_REFORMULATED_LIST = "REQUEST_GET_REASON_REFORMULATED_LIST";
export const REQUEST_GET_REASON_REFORMULATED_LIST_SUCCESS = "REQUEST_GET_REASON_REFORMULATED_LIST_SUCCESS";
export const REQUEST_GET_REASON_REFORMULATED_LIST_FAIL = "REQUEST_GET_REASON_REFORMULATED_LIST_FAIL";
export const REQUEST_PUT_CANCEL_PROCESS = "REQUEST_PUT_CANCEL_PROCESS";
export const REQUEST_PUT_CANCEL_PROCESS_SUCCESS = "REQUEST_PUT_CANCEL_PROCESS_SUCCESS";
export const REQUEST_PUT_CANCEL_PROCESS_FAIL = "REQUEST_PUT_CANCEL_PROCESS_FAIL";
export const REQUEST_PUT_PAUSE_PROCESS = "REQUEST_PUT_PAUSE_PROCESS";
export const REQUEST_PUT_PAUSE_PROCESS_SUCCESS = "REQUEST_PUT_PAUSE_PROCESS_SUCCESS";
export const REQUEST_PUT_PAUSE_PROCESS_FAIL = "REQUEST_PUT_PAUSE_PROCESS_FAIL";
export const REQUEST_PUT_REFORMULATE_PROCESS = "REQUEST_PUT_REFORMULATE_PROCESS";
export const REQUEST_PUT_REFORMULATE_PROCESS_SUCCESS = "REQUEST_PUT_REFORMULATE_PROCESS_SUCCESS";
export const REQUEST_PUT_REFORMULATE_PROCESS_FAIL = "REQUEST_PUT_REFORMULATE_PROCESS_FAIL";
export const REQUEST_PUT_SELECT_PARTNER_PROCESS = "REQUEST_PUT_SELECT_PARTNER_PROCESS";
export const REQUEST_PUT_SELECT_PARTNER_PROCESS_SUCCESS = "REQUEST_PUT_SELECT_PARTNER_PROCESS_SUCCESS";
export const REQUEST_PUT_SELECT_PARTNER_PROCESS_FAIL = "REQUEST_PUT_SELECT_PARTNER_PROCESS_FAIL";
export const REQUEST_PUT_VALIDATE_PROCESS = "REQUEST_PUT_VALIDATE_PROCESS";
export const REQUEST_PUT_VALIDATE_PROCESS_SUCCESS = "REQUEST_PUT_VALIDATE_PROCESS_SUCCESS";
export const REQUEST_PUT_VALIDATE_PROCESS_FAIL = "REQUEST_PUT_VALIDATE_PROCESS_FAIL";
export const REQUEST_PUT_ADD_SCHEDULE_PROCESS = "REQUEST_PUT_ADD_SCHEDULE_PROCESS";
export const REQUEST_PUT_ADD_SCHEDULE_PROCESS_SUCCESS = "REQUEST_PUT_ADD_SCHEDULE_PROCESS_SUCCESS";
export const REQUEST_PUT_ADD_SCHEDULE_PROCESS_FAIL = "REQUEST_PUT_ADD_SCHEDULE_PROCESS_FAIL";
export const REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS = "REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS";
export const REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS_SUCCESS = "REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS_SUCCESS";
export const REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS_FAIL = "REQUEST_POST_PROCESS_UPLOAD_DOCUMENTS_FAIL";
export const REQUEST_PUT_PROCESS_DETAILS = "REQUEST_PUT_PROCESS_DETAILS";
export const REQUEST_PUT_PROCESS_DETAILS_SUCESS = "REQUEST_PUT_PROCESS_DETAILS_SUCESS";
export const REQUEST_PUT_PROCESS_DETAILS_FAIL = "REQUEST_PUT_PROCESS_DETAILS_FAIL";
export const RESET_PROCESS_DETAILS = "RESET_PROCESS_DETAILS";
export const REQUEST_PUT_REFORMULATE_PROCESS_PARTNER = "REQUEST_PUT_REFORMULATE_PROCESS_PARTNER";
export const REQUEST_PUT_REFORMULATE_PROCESS_PARTNER_SUCCESS = "REQUEST_PUT_REFORMULATE_PROCESS_PARTNER_SUCCESS";
export const REQUEST_PUT_REFORMULATE_PROCESS_PARTNER_FAIL = "REQUEST_PUT_REFORMULATE_PROCESS_PARTNER_FAIL";
export const REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER = "REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER";
export const REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER_SUCCESS = "REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER_SUCCESS";
export const REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER_FAIL = "REQUEST_PUT_REFORMULATE_PROCESS_SERVICE_MANAGER_FAIL";

export const COMMENTS_CHARACTERS_LIMIT = 1500;

export const PROCESS_DETAILS_INPUT_NAMES = {
    IS_REFORMULATED: 'is_reformulated',
    /* GENERAL */
    DESCRIPTION: 'description',
    CLIENT_NAME: 'client_name',
    PARTNER_DESCRIPTION: 'partner_description',
    STATE_TAG: 'state_tag',
    STATE_ID: 'state_id',
    UPDATE_AT: 'updated_at',
    SIGNATURE_DATE: 'customer_signed_date',
    USER_PROPOSAL: 'user_proposal',
    EMAIL_PROPOSAL: 'email_proposal',
    IS_POWER_INCREASE: 'is_power_increase',
    PREVIOUS_PEAK_PANNEL_POWER: 'previous_peak_power',
    PREVIOUS_NOMINAL_POWER: 'previous_nominal_power',
    CONTRACT_ID: 'contract_id',
    PRODUCT_TYPE_ID: 'product_type_id',
    AGREED_DATE: 'agreed_date',
    SALESFORCE_ID: 'crm_id',
    PROPOSAL_NUMBER: 'proposal_sf_id',
    IS_STANDARD: 'standard',
    IS_STANDARD_REASON: 'not_standard_reason_description',
    IS_STANDARD_REASON_TAG: 'not_standard_reason_tag',
    IS_VIP: 'is_vip',
    IS_VYN: 'is_vyn',
    IS_VYN_REASON: 'vyn_reason_description',
    IS_VYN_REASON_TAG: 'vyn_reason_tag',
    /* CLIENT */
    CONTACT_NAME: 'contact_name',
    FISCAL_NUMBER: 'fiscal_number',
    CONTACT_PHONE: 'contact_phone',
    CONTACT_EMAIL: 'contact_email',
    ACTIVITY_SECTOR: 'activity_sector',
    CPE: 'cpe',
    ADDRESS: 'address',
    LATIDUE: 'latitude',
    LONGITUDE: 'longitude',
    CONTRACTED_POWER: 'contracted_power',
    REQUIRED_POWER: 'required_power',
    VOLTAGE_POWER: 'voltage_level',
    SERVICE_MANAGER_NAME: 'service_manager_name',
    SERVICE_MANAGER_EMAIL: 'service_manager_email',
    EDP_CLIENT: 'edp_client',
    AVERAGE_TARIFF: 'average_tariff',
    DISTRICT: 'district',
    SALES_OPERATION_MANAGER_DESCRIPTION: 'sales_operation_manager_description',
    SALES_OPERATION_MANAGER_ID: 'sales_operation_manager_id',
    LOT_NUMBER: 'lot_number',
    /* FINANCIAL */
    FINANCIAL_INFORMATION: 'financial_information',
    PRICE: 'price',
    WP_PRICE: 'price_wp',
    ESTIMATED_PRICE: 'estimated_price',
    ESTIMATED_SPECIFIC_PRICE: 'estimated_specific_price',
    SAVINGS: 'savings',
    COST: 'process_cost',
    PAYMENT_MODEL: 'payment_model',
    TIR: 'tir',
    TIR_WACC: 'tir_wacc',
    WACC: 'wacc',
    TARIFF: 'tariff',
    BUSINESS_MODEL: 'business_model',
    MAX_ADDITIONAL_COST: 'max_additional_cost',
    SPECIFIC_PRODUCTION: 'specific_production',
    SPECIFIC_PRODUCTION_REFERENCE: 'specific_production_reference',
    SHARE_SAVINGS: 'share_savings',
    MARGIN: 'margin',
    MONTHLY_PAYMENTS_NUMBER: 'monthly_payments_number',
    MONTHLY_FEE_SELECTED: 'monthly_fee_selected',
    SERVICE_MONTHLY_PAYMENTS_NUMBER: 'service_monthly_payments_number',
    SERVICE_MONTHLY_FEE_SELECTED: 'service_monthly_fee_selected',
    ANNUAL_PRICE: 'anual_price',
    COMPANY_REVENUES: 'company_revenues',
    TARIFF_MIN: 'tariff_min',
    PROJECT_DURATION: 'project_duration',
    CONTRACT_PRODUCTION: 'contracted_production',
    // 
    PAYMENT_METHOD: 'payment_method',
    SHARE_SAVING_SELECTED_COMPANY: 'share_saving_selected_company',
    SET_UP_COST: 'set_up_cost',
    TARIFF_SELECTED: 'tariff_selected',
    TIR_NEW: 'tir_new',
    FINANTIAL_ANALYSIS: 'finantial_analysis',
    /* TECHNICAL */
    SIMULATION_OUTPUTS: 'simulation_outputs',
    PRE_SIMULATION_OUTPUTS: 'pre_simulation_outputs',
    STRUCTURE_LEVELS: 'structure_levels',
    TECHNICAL_BRIEF: 'technical_brief',
    /* TECHNICAL SUMMARY */
    TOTAL_PANNELS: 'total_panel_number',
    TOTAL_IMPLEMENTATION_AREA: 'total_area',
    TOTAL_PEAK_POWER: 'total_peak_power',
    ANNUAL_INJECTION: 'annual_injection',
    INVERTER_POWER: 'inverter_power',
    ANNUAL_PRODUCTION: 'annual_production',
    MAINTENANCE_DURATION: 'duracao_manutencao',
    SELL_GRID: 'sell_grid',
    TOTAL_CHARGERS: 'nr_chargers',
    TOTAL_AC_POWER: 'power_ac_total',
    TOTAL_DC_POWER: 'power_dc_total',
    TOTAL_CABLE_METERS: 'cable_total',
    TOTAL_BATTERIES: 'nr_batteries',
    TOTAL_BATTERY_POWER: 'total_power',
    PANEL_COMPOSITION: 'panel_composition',
    INVERTER_COMPOSITION: 'inverter_composition',
    PRE_COMPOSICAO_PAINEIS_AGG: 'pre_composicao_paineis_agg',
    POS_COMPSICAO_PAINEIS_AGG: 'pos_composicao_paineis_agg',
    PRE_COMPOSICAO_INVERSOES_AGG: 'pre_composicao_inversores_agg',
    POS_COMPOSICAO_INVERSORES_AGG: 'pos_composicao_inversores_agg',
    // BATTERY
    HAS_BATTERY: 'has_battery',
    BATTERY_MANUFACTURER: 'battery_manufacturer',
    BATTERY_DESCRIPTION: 'battery_description',
    BATTERY_MODEL: 'battery_model',
    BATTERY_CAPACITY_KWH: 'battery_capacity_kwh',
    BATTERY_POWER_KW: 'battery_power_kw',
    BATTERY_INVERTER_POWER_KW: 'battery_inverter_power_kw',
    BATTERY_NR_OF_INVERTERS: 'battery_nr_of_inverters',
    BATTERY_INVERTER_IS_HYBRID: 'battery_inverter_is_hybrid',
    /* EQUIPMENT TECHNICAL DETAILS */
    TECHNICAL_DETAILS: 'technical_details',
    NUMERO_PAINES_GRUPO_PAINES: 'numero_paineis_grupo_paineis',
    COMPOSICAO_PAINES: 'composicao_paineis',
    COMPOSICAO_INVERSORES: 'composicao_inversores',
    AREA_PAINES_GRUPO_PAINES: 'area_paineis_grupo_paineis',
    POTENCIA_PICO_POR_PAINEL_WP: 'potencia_pico_por_painel_Wp',
    POS_POTENCIA_PICO_POR_PAINEL_WP_AGG: 'pos_potencia_pico_por_painel_Wp_agg',
    PRE_POTENCIA_PICO_POR_PAINEL_WP_AGG: 'pre_potencia_pico_por_painel_Wp_agg',
    POTENCIA_PICO_KWP_GRUPO_PAINES: 'potencia_pico_kwp_grupo_paineis',
    // REF_INCLINACAO_GRAUS: 'ref_inclinacao_graus',
    // REF_ORIENTACAO_GRAUS: 'ref_orientacao_graus',
    INCLINACAO_GRAUS_GRUPO_PAINES: 'inclinacao_graus_grupo_paineis',
    ORIENTACAO_GRAUS_GRUPO_PAINEIS: 'orientacao_graus_grupo_paineis',
    POTENCIA_NOMINAL_INVERSORES_KW: 'potencia_nominal_inversores_kW',
    PRODUCAO_ANUAL_MWH: 'producao_anual_MWh',
    VENDA_A_REDE: 'venda_a_rede',
    DURACAO_MANUTENCAO: 'duracao_manutencao',
    // ME,
    PLATAFORMA_GESTAO: 'plataforma_gestao',
    NR_CARREGADORES: 'nr_carregadores',
    DISTANCIA_QUADRO_M: 'distancia_quadro_m',
    LOCAL_INSTALAÇÃO: 'local_instalacao',
    TIPO_FIXACAO: 'tipo_fixacao',
    GAMA: 'gama',
    MARCA: 'marca',
    MODELO: 'modelo',
    POTENCIA_AC_KVA: 'potencia_ac_kva',
    POTENCIA_DC_KW: 'potencia_dc_kw',
    NR_TOMADAS: 'nr_tomadas',
    CONFIG_TOMADAS: 'config_tomadas',
    RAPIDO: 'rapido',
    OFERTA_ZERO: 'oferta_zero',
    TOTAL_POWER: 'total_power',
    // CFP
    POTENCIA_BATERIA_KVAR: 'potencia_bateria_kVAr',
    POTENCIA_NECESSARIA_KVAR: 'potencia_necessaria_kVAr',
    TIPO_DE_CONDENSADORES: 'tipo_de_condensadores',
    FILTRO_HARMONICOS: 'filtro_harmonicos',
    //RR
    TOTAL_GROUPS: 'total_groups',
    AREA_TRANSLUCID: 'area_translucid',
    ASBESTOS_PRESENCE: 'asbestos_presence',
    GROUP_AREA: 'group_area',
    GROUP_NAME: 'group_name',
    ID_GRUPO: 'id_grupo',
    MATERIAL_INSTALLED: 'material_installed',
    MATERIAL_REMOVED: 'material_removed',
    MATERIAL_THICKNESS: 'material_thickness',
    MATERIAL_TRANSLUCID: 'material_translucid',
    PERCENTAGE_TRANSLUCID: 'percentage_translucid',
    PRESENCE_AGGRAVATION_1: 'presence_aggravation_1',
    PRESENCE_AGGRAVATION_2: 'presence_aggravation_2'

}

export const TIMELINE_INPUT_NAMES = {
    ACTIVE: 'active',
    STATE_ID: 'state_id',
    TIR_NEW: 'tir_new',
    FINANTIAL_ANALYSIS: 'finantial_analysis',
    MAX_ADDITIONAL_COST: 'max_additional_cost',
    PROCESS_MARGIN: 'process_margin',
    IS_POWER_OK: 'is_power_ok',
    IS_INSTALLATION_COST_OK: 'is_installation_cost_ok'
}

export const PRODUCTS_WITH_TECHNICAL_SUMMARY = [PRODUCT_TAG.SPV, PRODUCT_TAG.ME, PRODUCT_TAG.CFP]

export const PROCESS_UPLOAD_INPUT_NAMES = {
    REAL_POWER_INSTALLED_PV: 'installed_power_kwp_pv_ss',
    REAL_POWER_INSTALLED_CFP: 'installed_power_kvar_cfp_ss',
    REAL_POWER_INSTALLED_IE: 'installed_power_kw_el_ss',
    REAL_AC_POWER_INSTALLED: 'installed_power_em_kva_me_ss',
    REAL_DC_POWER_INSTALLED: 'installed_power_em_kw_me_ss',
    REAL_INVERTERS_POWER: 'nominal_power_inverters_kw_pv_ss',
    EQUIPMENT_QUANTITY: 'equipment_quantity_ss',
    BATTERY_QUANTITY: 'battery_quantity_ss',
    CHARGER_QUANTITY: 'charger_quantity_ss',
    PANNEL_QUANTITY: 'pannel_quantity_ss',
    HARMONIC_FILTER: 'harmonic_filter_pfc_ss',
    EMS: 'ems_ss',
    CONCENTRATOR_QUANTITY: 'ems_concentrators_quantity_ss',
    ANNUAL_PRODUCTION: 'annual_estimated_production_kwh_ss',
    ANNUAL_PRODUCTION_MWH: 'annual_estimated_production_mwh_ss',
    /* FINANCIAL */
    REAL_COST_EQUIPMENT: 'equipment_cost',
    REAL_COST_FACILITY: 'installation_cost',
    ANNUAL_MAINTENANCE_COST: 'annual_maintenance_cost ',
    VALID_PRODUCTION_ASSUMPTIONS: 'valid_production_assumptions ',
    COSTS_OUTSIDE_ASSUMPTIONS: 'costs_outside_assumptions',
    COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE: 'costs_outside_assumptions_attributable',
    TYPE_OF_COSTS: 'type_of_costs',
    PANEL_COST: 'panel_cost',
    INVERTER_COST: 'inverter_cost',
    BATTERY_COST: 'battery_cost',
    MONITORING_COST: 'monitoring_cost',
    /* UPLOAD FILES */
    UPLOAD_FILES: 'upload_files',
    CURVE_LOAD: 'curveLoad',
    COMMENTS: 'state_comments'
}

export const PROCESS_DETAILS_UPLOAD_DOCS_QUESTIONS = {
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_PV]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_PV,
        label: 'processUploadPricingDocs.label.installedPower',
        unit: 'kwp'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_CFP]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_CFP,
        label: 'processUploadPricingDocs.label.installedPower',
        unit: 'kvar'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_IE]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_POWER_INSTALLED_IE,
        label: 'processUploadPricingDocs.label.installedPower',
        unit: 'kw'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_AC_POWER_INSTALLED]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_AC_POWER_INSTALLED,
        label: 'processUploadPricingDocs.label.installedPowerAC',
        unit: 'kva'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_DC_POWER_INSTALLED]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_DC_POWER_INSTALLED,
        label: 'processUploadPricingDocs.label.installedPowerDC',
        unit: 'kw'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_INVERTERS_POWER]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_INVERTERS_POWER,
        label: 'processUploadPricingDocs.label.nominalPower',
        unit: 'kw'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.EQUIPMENT_QUANTITY]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.EQUIPMENT_QUANTITY,
        label: 'processUploadPricingDocs.tooltip.equipmentQuantity.'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.HARMONIC_FILTER]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.HARMONIC_FILTER,
        label: 'processUploadPricingDocs.label.harmonicFilter'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.EMS]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.EMS,
        label: 'processUploadPricingDocs.label.ems'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.CONCENTRATOR_QUANTITY]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.CONCENTRATOR_QUANTITY,
        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.ANNUAL_PRODUCTION_MWH]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.ANNUAL_PRODUCTION_MWH,
        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
        unit: 'mwh'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_COST_EQUIPMENT]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_COST_EQUIPMENT,
        label: 'processUploadPricingDocs.label.realCostEquipment',
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.REAL_COST_FACILITY]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.REAL_COST_FACILITY,
        label: 'processUploadPricingDocs.label.realCostFacility'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.ANNUAL_MAINTENANCE_COST]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.ANNUAL_MAINTENANCE_COST,
        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.TYPE_OF_COSTS]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.TYPE_OF_COSTS,
        label: 'viewBudget.label.typeOfCost'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.PANEL_COST]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.PANEL_COST,
        label: 'viewBudget.label.panelsCost',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.INVERTER_COST]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.INVERTER_COST,
        label: 'viewBudget.label.invertersCost',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.BATTERY_COST]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.BATTERY_COST,
        label: 'viewBudget.label.batteryCost',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.MONITORING_COST]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.MONITORING_COST,
        label: 'viewBudget.label.monitoringCost',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            },
        }
    },
    [PROCESS_UPLOAD_INPUT_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
        label: 'processUploadPricingDocs.label.costsOutsideAssumptions'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
        label: 'processUploadPricingDocs.label.validProductionAssumptions'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.UPLOAD_FILES]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.UPLOAD_FILES,
        label: 'processUploadPricingDocs.label.'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.CURVE_LOAD]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.CURVE_LOAD,
        label: 'processUploadPricingDocs.label.uploadLoadCurve',
        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
        downloadLabel: 'processUploadPricingDocs.label.loadCurve',
        invalidFormat: 'processUploadPricingDocs.label.invalidFormat'
    },
    [PROCESS_UPLOAD_INPUT_NAMES.COMMENTS]: {
        name: PROCESS_UPLOAD_INPUT_NAMES.COMMENTS,
        label: 'processUploadPricingDocs.label.comments',
        validation: {
            required: "yup.message.required",
            maxLength: {
                value: COMMENTS_CHARACTERS_LIMIT,
                message: 'yup.message.string.maxCharacters'
            },
        }
    },
}

export const PROCESS_DETAILS_EQUIPMENTS_GROUP = {
    [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
        validation: {
            required: "yup.message.required",
            max: {
                value: 2500,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: 0,
                message: 'yup.dynamic.valuesBetween'
            },
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
        validation: {
            required: "yup.message.required",
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
        validation: {
            required: "yup.message.required",
            max: {
                value: 2500,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: 0,
                message: 'yup.dynamic.valuesBetween'
            },
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
        validation: {
            required: "yup.message.required",
            max: {
                value: 4500,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: 0,
                message: 'yup.dynamic.valuesBetween'
            },
        },
        unit: 'sm'
    },
    [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
        validation: {
            required: "yup.message.required",
            max: {
                value: 1000,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: 0,
                message: 'yup.dynamic.valuesBetween'
            },
        },
        unit: 'wp'
    },
    [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        },
        unit: 'kwp'
    },
    [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        },
        unit: 'kw'
    },
    [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        },
        unit: 'mwh'
    },
    [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
        validation: {
            required: "yup.message.required",
        },
    },
    [PROCESS_DETAILS_INPUT_NAMES.DURACAO_MANUTENCAO]: {
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        },
        unit: 'year'
    },
    [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
        unit: 'dg',
        validation: {
            required: "yup.message.required",
            max: {
                value: 45,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: 0,
                message: 'yup.dynamic.valuesBetween'
            },
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
        unit: 'dg',
        validation: {
            required: "yup.message.required",
            max: {
                value: 180,
                message: 'yup.dynamic.valuesBetween'
            },
            min: {
                value: -180,
                message: 'yup.dynamic.valuesBetween'
            },
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
        validation: {
            required: "yup.message.required",
        },
    },
    [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
        validation: {
            required: "yup.message.required",
        },
    },
    [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
        unit: 'kva',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
        unit: 'kva',
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        }
    },
    [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
        validation: {
            min: {
                value: 0,
                message: 'yup.message.number.positive'
            }
        }
    },
}

export const FINANTIAL_ANALYSIS = {
    RED: 1,
    YELLOW: 2,
    GREEN: 3,
}

export const REFORMULATED_ERROR_TAGS = {
    INVALID_CONTRACT_INFORMATION: 'INVALID_CONTRACT_INFORMATION',
    INVALID_CONTRACT: 'INVALID_CONTRACT',
    DUPLICATE_CONTRACT_ID: 'DUPLICATE_CONTRACT_ID',
    INVALID_CONTRACT_ID_NEW: 'INVALID_CONTRACT_ID_NEW',
    DUPLICATE_CONTRACT_ID_NEW: 'DUPLICATE_CONTRACT_ID_NEW'
}

/**
 * getTemplateCurveLoadURL
 * 
 * @param {*} type 
 */
export const getTemplateCurveLoadURL = (type) => {
    switch (type) {
        case URL_TEMPLATE_15MIN_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_15MIN_CURVE_LOAD;

        case URL_TEMPLATE_HOURLY_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_HOURLY_CURVE_LOAD;

        default:
            return getBaseURL();
    }
}

//TEMPLATES
export const URL_TEMPLATE_15MIN_CURVE_LOAD = 'template_15min_curve_load';
export const URL_TEMPLATE_HOURLY_CURVE_LOAD = 'template_hourly_curve_load';

const MAX_INPUT_VALUE = 10; // 10 digits long
export const maxValueLimit = ({ value }) => value.length <= MAX_INPUT_VALUE;

export const ME_INSTALATION_LOCAL_IDS = {
    INTERNAL: 1,
    EXTERNAL: 2
}

export const ME_FIXATION_TYPE_IDS = {
    WALL: 1,
    GROUND: 2
}
export const ME_INPUTS_LABELS = {
    [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
        [ME_INSTALATION_LOCAL_IDS.INTERNAL]: 'processDetails.label.local_instalacao.internal',
        [ME_INSTALATION_LOCAL_IDS.EXTERNAL]: 'processDetails.label.local_instalacao.external',
    },
    [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
        [ME_FIXATION_TYPE_IDS.WALL]: 'processDetails.label.tipo_fixacao.wall',
        [ME_FIXATION_TYPE_IDS.GROUND]: 'processDetails.label.tipo_fixacao.ground',
    }
}

export const TIR_ERRORS = {
    NOMINAL_POWER_TRESHOLD: 'NOMINAL_POWER_TRESHOLD',
    CUSTOMER_RISK: 'CUSTOMER_RISK',
    MISSING_TARIFF: 'MISSING_TARIFF',
    GENERAL_ERROR: 'GENERAL_ERROR',
    MISSING_LOCAL_ENERGY_COMMUNITY_OBJ: 'MISSING_LOCAL_ENERGY_COMMUNITY_OBJ'
}

export const TYPES_OF_COSTS = {
    TOTAL: 'total',
    DETAILED: 'detailed',
}

export const WARNING_TYPES = {
    RED: 'red',
    GREEN: 'green'
}